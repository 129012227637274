import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Card,
  Stack,
  DialogActions,
  Box,
  Divider,
  Icon,
  IconButton,
} from '@mui/material';
import { TextInputField, SelectInputField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useGetVersion, useUpdateVersion } from '../hooks';
import { schema, defaultValues, sanitizeRequest } from '../schemas/approval';
const TOKENS = ['USDT'];

function ApprovalTab() {
  const { id } = useParams();
  const { data, isLoading: isFetchingVersion } = useGetVersion({ id });
  const { mutate: updateVersion, isLoading } = useUpdateVersion({ id });
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset, control } = methods;
  const {
    fields: need_approvals,
    append: appendApproval,
    remove: removeApproval,
  } = useFieldArray({ control, name: 'need_approvals' });

  const onSubmit = (values) => {
    const approvalPayload = sanitizeRequest(values);
    const payload = { ...data, need_approvals: approvalPayload.need_approvals };
    console.log('payload: ', payload);
    updateVersion(payload);
  };

  useEffect(() => {
    if (data.need_approvals) {
      console.log('reset');
      reset(data);
    }
  }, [data]);

  if (isFetchingVersion) {
    return null;
  }

  const tokenOptions = TOKENS.map((token) => ({ label: token, value: token }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} sm={1} />
      <Grid item xs={10} sm={10}>
        <Card sx={{ overflow: 'visible' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Approval Settings
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                Choose limit amount of tokens that need to be approved by the
                admin
              </SoftTypography>
            </Stack>
            <Stack m={3}>
              <SoftBox>
                {_.map(need_approvals, (field, index) => (
                  <Grid container key={field.id}>
                    <Grid item xs={11}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <SelectInputField
                              options={tokenOptions}
                              label={`token`}
                              name={`need_approvals[${index}].token`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextInputField
                              label={`amount`}
                              name={`need_approvals[${index}].amount`}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => removeApproval(index)}
                        sx={{ mt: 2 }}
                      >
                        <Icon>delete_forever</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <SoftButton
                  sx={{ mt: 2 }}
                  onClick={() => appendApproval({ token: '', amount: 0 })}
                >
                  Add Approval limit
                </SoftButton>
              </SoftBox>
            </Stack>
            <Stack ml={1}>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  type='submit'
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
                <SoftButton variant='gradient' color='error'>
                  Delete
                </SoftButton>
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ApprovalTab;
