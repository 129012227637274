import { useState, useEffect, useRef } from 'react';

import AssessmentIcon from '@mui/icons-material/Assessment';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ActionCell from 'components/ActionCell';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import StatusChip from 'components/StatusChip';
import TabPanel, { a11yProps } from 'components/TabPanel';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import DataTable from 'rootComponents/Tables/DataTable';
import { fDateTimeSuffix } from 'utils/formatTime';
import { set, get } from 'utils/localStorage';

import FeesDetails from './components/FeesDetails';
import PaymentDetails from './components/PaymentDetails';
import SummaryReport from './components/SummaryReport';
import TransactionFilters from './components/TransactionFilters';
import { sanitizeRequest } from './schemas';
import { useGetTransactions } from './useTransactions';

const LC_PAYLOAD = 'transactionReportingPayload';
const ACTION_CELL = [
  {
    type: 'visibility',
    title: 'Transaction Details',
  },
];

const Transactions = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(25);
  const [tab, setTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDownload, setDataToDownload] = useState([{}]);
  // Destructuring the object of all selected filters to obtain only the necessary

  const { mutate, isLoading, data } = useGetTransactions();
  const { mutate: downloadMutate, data: downloadData } = useGetTransactions();

  const transactionReportingPayload = get(LC_PAYLOAD) || {};

  const defaultValues = transactionReportingPayload;

  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (transactionReportingPayload) {
      mutate(transactionReportingPayload);
    } else {
      mutate({
        page: 1,
        transactionId: '',
        limit: pageSize,
      });
    }
  }, []);

  //Handles the click event for the download action
  const handleDownloadClick = async ({ amount, ref }) => {
    return new Promise(function (resolve, reject) {
      if (ref) {
        const payload = {
          ...transactionReportingPayload,
          page: 1,
          limit: amount,
        };
        downloadMutate(payload, {
          onSuccess: async (data) => {
            setDataToDownload(_.get(data, 'docs', []));
            await setTimeout(() => {
              ref.current?.link.click();
              resolve();
            }, 3000);
          },
        });
      }
    });
  };

  // create a function that navigates to the detail page for a given transaction
  const handleDetailClick = (id) => {
    navigate(`/transactions/${id}`);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, 'docs', []), (item) => {
      return {
        session: item.id,
        currency: item.currency,
        merchant: item.merchant,
        tx_id: item.tx_id == null ? 'N/A' : item.tx_id,
        option: item.payment_details.card == null ? 'Mobile' : 'Card',
        status: <StatusChip label={item.status} size='small' />,
        created_date: fDateTimeSuffix(item.created_date),
        payment_details: <PaymentDetails details={item.payment_details} />,
        fees_details: <FeesDetails fees={item.fees} />,
        actions: (
          <ActionCell
            actions={ACTION_CELL}
            onVisibilityClick={() => handleDetailClick(item.id)}
          />
        ),
      };
    });
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    set(LC_PAYLOAD, sanitizeRequest(values));
    mutate(sanitizeRequest(values));
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    const payload = transactionReportingPayload;
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    mutate(payload);
    setCurrentPage(page + 1);
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    const payload = transactionReportingPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    mutate(payload);
  };

  //tab handle
  const handleTabChange = (event, newValue) => {
    data.docs = [];
    setTab(newValue);
    setCurrentPage(1);
    setPageSize(25);
  };

  const handleReportLimitChange = (limit) => {
    const payload = transactionReportingPayload;
    payload.page = 1;
    payload.limit = limit;
    set(LC_PAYLOAD, payload);
    mutate(payload);
  };

  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <TransactionFilters />
      </FormProvider>

      {/* define tab */}
      <Grid
        container
        spacing={2}
        sx={{ flexGrow: 1, marginTop: 2 }}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid>
          <SoftBox sx={{ maxWidth: 400, marginTop: 2 }}>
            <AppBar position='static'>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label='basic tabs example'
                sx={{ backgroundColor: 'transparent' }}
                variant='standard'
              >
                <Tab
                  icon={<TableRowsIcon />}
                  label='Transaction Grid'
                  {...a11yProps(0)}
                  sx={{ marginRight: 2 }}
                />
                <Tab
                  icon={<AssessmentIcon />}
                  label='Summary Report'
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </SoftBox>
        </Grid>
      </Grid>

      <TabPanel value={tab} index={0}>
        <DataTable
          table={{
            columns: [
              { Header: 'session', accessor: 'session' },
              { Header: 'details', accessor: 'payment_details' },
              { Header: 'fees', accessor: 'fees_details' },
              { Header: 'tx id', accessor: 'tx_id' },
              { Header: 'option', accessor: 'option' },
              { Header: 'status', accessor: 'status' },
              { Header: 'created time', accessor: 'created_date' },
              { Header: 'actions', accessor: 'actions' },
            ],
            rows: getRows(),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, 'pagination.totalPages', 0)}
          manualPagination={true}
          rowCount={_.get(data, 'pagination.totalDocs', 0)}
          isLoading={isLoading}
          currentPage={currentPage}
          isDownloadable={true}
          dataToDownload={dataToDownload}
          isServerDownload={true}
          onDownloadClick={handleDownloadClick}
          filename='Transaction_Bitmambo.csv'
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <SummaryReport
          data={_.get(data, 'docs', [])}
          limitOnChange={handleReportLimitChange}
        />
      </TabPanel>
    </MainLayout>
  );
};

export default Transactions;

