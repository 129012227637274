import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';

import PaymentDetails from './components/PaymentDetails';
import TransactionSummary from './components/Summary';
import UserInfo from './components/UserInfo';
import { useGetTransaction } from './hooks';

// This code gets the transaction detail from the API and renders it as a tree view.
const TransactionDetail = () => {
  const { id } = useParams();
  const { data } = useGetTransaction(id);
  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TransactionSummary data={data} />
                </Grid>
                <Grid item xs={12}>
                  <PaymentDetails data={data} />
                </Grid>
                <Grid item xs={12}>
                  <UserInfo data={data} />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </MainLayout>
  );
};

export default TransactionDetail;
