import { colors } from '@mui/material';
import typography from 'assets/theme/base/typography';
import pxToRem from 'assets/theme/functions/pxToRem';

const { size } = typography;

const dialogTitle = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
      background: colors.grey[500],
      fontWeight: typography.h6,
      color: 'white',
      padding: '8px 16px',
    },
  },
};

export default dialogTitle;

