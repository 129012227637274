import typography from 'assets/theme/base/typography';
import SoftBox from 'components/SoftBox';
import SoftProgress from 'components/SoftProgress';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

function SummaryItem({ icon, title, amount, symbol, total }) {
  const { size } = typography;

  return (
    <SoftBox width='100%' py={1} mb={1}>
      <SoftBox display='flex' justifyContent='space-between' mb={1}>
        <SoftBox display='flex' alignItems='center' lineHeight={0}>
          <SoftBox mr={1} color={icon.color} fontSize={size.lg}>
            {icon.component}
          </SoftBox>
          <SoftTypography variant='button' fontWeight='medium' color='text'>
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant='button' fontWeight='medium' color='text'>
          {amount} {symbol}
        </SoftTypography>
      </SoftBox>
      <SoftProgress value={(amount / total) * 100} color='dark' />
    </SoftBox>
  );
}

// Typechecking props for the SummaryItem
SummaryItem.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  symbol: PropTypes.string,
  total: PropTypes.number.isRequired,
};

export default SummaryItem;
