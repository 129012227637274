import { Skeleton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';


function TableRowsLoader({ rowsNum, colsNum }) {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      <TableCell component='th' scope='row'>
        <Skeleton animation='wave' variant='text' />
      </TableCell>
      {_.map(_.range(colsNum - 1), (index) => (
        <TableCell key={index}>
          <Skeleton animation='wave' variant='text' />
        </TableCell>
      ))}
    </TableRow>
  ));
}

export default TableRowsLoader;
