export const networks = [
  {
    label: 'TRON',
    value: 'TRON',
  },
];

export const networkFeesUnits = [
  {
    label: 'USDT',
    value: 'USDT',
  },
];
