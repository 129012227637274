import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Breadcrumbs({ icon, title, route, light, customRoute }) {
  const routes = route.slice(0, -1);
  const customRoutes = customRoute.slice(0, -1);

  return (
    <SoftBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        <Link to='/'>
          <SoftTypography
            component='span'
            variant='body2'
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </SoftTypography>
        </Link>
        {customRoutes.length == 0 &&
          routes.map((el) => (
            <Link to={`/${el}`} key={el}>
              <SoftTypography
                component='span'
                variant='button'
                fontWeight='regular'
                textTransform='capitalize'
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {el}
              </SoftTypography>
            </Link>
          ))}
        {customRoutes.length !== 0 &&
          customRoutes.map((el) => (
            <Link to={`/${el.route}`} key={el.route}>
              <SoftTypography
                component='span'
                variant='button'
                fontWeight='regular'
                textTransform='capitalize'
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {el.label}
              </SoftTypography>
            </Link>
          ))}
        <SoftTypography
          variant='button'
          fontWeight='regular'
          textTransform='capitalize'
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}
        >
          {title.replace('-', ' ')}
        </SoftTypography>
      </MuiBreadcrumbs>
      <SoftTypography
        fontWeight='bold'
        textTransform='capitalize'
        variant='h6'
        color={light ? 'white' : 'dark'}
        noWrap
      >
        {title.replace('-', ' ')}
      </SoftTypography>
    </SoftBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
  customRoute: [],
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
  customRoute: PropTypes.arrayOf(PropTypes.object),
};

export default Breadcrumbs;

