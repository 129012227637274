import axios from 'axios';
import _ from 'lodash';

import { getToken } from './cache';
import { toastError } from './toast';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30 * 1000,
});

// request interceptor
request.interceptors.request.use((axiosConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${getToken()}`,
  };

  return axiosConfig;
});

request.interceptors.response.use(
  (response) => _.get(response, 'data.message') || _.get(response, 'data'),
  (error) => {
    const config = _.get(error, 'response.config');
    const data = _.get(error, 'response.data');

    // const statusCode = _.get(data, 'statusCode');
    const method = _.get(config, 'method');
    const message = _.get(data, 'errors[0]');

    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = '/sign-in';
    }

    if (['post', 'delete', 'put'].includes(method)) {
      toastError({ description: message });
    }
    return Promise.reject(error);
  }
);

export default request;

