import StatusChip from 'components/StatusChip';
import _ from 'lodash';
import FeesDetails from 'pages/transactions/components/FeesDetails';
import PaymentDetails from 'pages/transactions/components/PaymentDetails';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import DataTable from 'rootComponents/Tables/DataTable';
import { fDateTimeSuffix } from 'utils/formatTime';

import ApproveTransactionModel from './components/ApproveTransactionModel';
import { useGetNeedApprovalTransactions } from './hooks';

const Approvals = () => {
  const { isLoading, data, refetch } = useGetNeedApprovalTransactions();

  // Destructuring the object of all selected filters to obtain only the necessary
  const getRows = () => {
    return _.map(_.get(data, 'docs', []), (item) => {
      return {
        session: item.id,
        currency: item.currency,
        merchant: item.merchant,
        option: item.payment_details.card == null ? 'Mobile' : 'Card',
        status: <StatusChip label={item.status} size='small' />,
        created_date: fDateTimeSuffix(item.created_date),
        payment_details: <PaymentDetails details={item.payment_details} />,
        fees_details: <FeesDetails fees={item.fees} />,
        actions: (
          <ApproveTransactionModel transactionId={item.id} refetch={refetch} />
        ),
      };
    });
  };
  return (
    <MainLayout>
      <MainNavbar />

      {/* define tab */}

      <DataTable
        table={{
          columns: [
            { Header: 'session', accessor: 'session' },
            { Header: 'details', accessor: 'payment_details' },
            { Header: 'fees', accessor: 'fees_details' },
            { Header: 'option', accessor: 'option' },
            { Header: 'status', accessor: 'status' },
            { Header: 'created time', accessor: 'created_date' },
            { Header: 'actions', accessor: 'actions' },
          ],
          rows: getRows(),
        }}
        pageCount={_.get(data, 'pagination.totalPages', 0)}
        rowCount={_.get(data, 'pagination.totalDocs', 0)}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};

export default Approvals;

