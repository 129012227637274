import { forwardRef } from 'react';

import SoftBox from 'components/SoftBox';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

const SelectInputField = forwardRef(
  ({ options, placeholder, name, label, ...rest }, ref) => {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <SoftBox
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            height='100%'
            mb={1.5}
          >
            {label && (
              <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
                <SoftTypography
                  component='label'
                  variant='caption'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  {label}
                </SoftTypography>
              </SoftBox>
            )}
            <SoftSelect
              error={error}
              {...rest}
              ref={ref}
              {...field}
              options={options}
              placeholder={placeholder}
              value={
                options.find((option) => option?.value === field.value) || null
              }
              onChange={(val) => {
                return field.onChange(_.get(val, 'value', null));
              }}
            />
            {error && (
              <SoftBox mt={0.75}>
                <SoftTypography component='div' variant='caption' color='error'>
                  {_.get(error, 'message', 'Unknown error')}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        )}
      />
    );
  }
);

// Setting default values for the props of SelectInputField
SelectInputField.defaultProps = {
  label: '',
  placeholder: 'Select an option',
  message: 'Required',
};

// Typechecking props for the SelectInputField
SelectInputField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default SelectInputField;

