import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import { useDisclosure } from 'hooks/useDisclosure';
import { useForm } from 'react-hook-form';

import { useCreateVersion } from '../hooks';
import { schema, defaultValues, sanitizeRequest } from '../schemas';
import VersionForm from './VersionForm';

const AddVersionModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, setValue } = methods;

  //hooks
  const { isLoading, mutate } = useCreateVersion();

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(sanitizeRequest(values), {
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    setValue('default_language', 'fr');
  }, []);

  return (
    <>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction='row' justifyContent='flex-end'>
          <SoftButton variant='gradient' color='info' onClick={onOpen}>
            Create Version
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpen}
        handleClose={onClose}
        fullWidth
        maxWidth='md'
        scroll='body'
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create Version
          </DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <SoftBox p={3}>
              <VersionForm />
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant='gradient' color='dark' onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant='gradient'
              color='info'
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
            >
              Create Version
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
// Typechecking props for the ResetUserModal
export default AddVersionModal;
