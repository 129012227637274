/**
=========================================================
* Soft UI Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import pxToRem from 'assets/theme/functions/pxToRem';
import SoftButtonRoot from 'components/SoftButton/SoftButtonRoot';
import PropTypes from 'prop-types';

const SoftButton = forwardRef(
  (
    { isLoading, color, variant, size, circular, iconOnly, children, ...rest },
    ref
  ) => {
    const renderChildren = () => {
      if (isLoading) {
        return (
          <CircularProgress
            size={pxToRem(18)}
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        );
      } else {
        return children;
      }
    };
    return (
      <SoftButtonRoot
        {...rest}
        ref={ref}
        color='primary'
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly }}
      >
        {renderChildren()}
      </SoftButtonRoot>
    );
  }
);

// Setting default values for the props of SoftButton
SoftButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'white',
  circular: false,
  iconOnly: false,
  isLoading: false,
};

// Typechecking props for the SoftButton
SoftButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default SoftButton;

