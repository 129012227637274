import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import pxToRem from 'assets/theme/functions/pxToRem';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { useDisclosure } from 'hooks/useDisclosure';
import PropTypes from 'prop-types';

import { useDeleteFee } from '../hooks';

const DeleteFeeModal = ({ versionId, version, refetch }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //hooks
  const { isLoading, mutate } = useDeleteFee({ id: versionId });

  // create a function that handles the form submission
  const onSubmit = () => {
    mutate(versionId, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const handleClose = () => {
    onClose();
    refetch();
  };

  return (
    <>
      <SoftButton variant='gradient' color='error' onClick={onOpen}>
        Delete
      </SoftButton>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          Delete {version} Fee
        </DialogTitle>
        <DialogContent>
          <SoftBox p={3}>
            <SoftBox component='form' role='form'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                  >
                    <SoftBox display='flex' alignItems='center'>
                      <DeleteForeverIcon
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          color: (theme) => theme.palette.error.main,
                        }}
                      />
                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography variant='h6' fontWeight='medium'>
                          Delete {version} Fee
                        </SoftTypography>
                        <SoftTypography
                          variant='button'
                          color='text'
                          fontWeight='regular'
                        >
                          Are you sure you want to fee setting for {version}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton
            variant='gradient'
            color='dark'
            sx={{ mt: 2 }}
            onClick={handleClose}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant='gradient'
            color='info'
            type='submit'
            sx={{ mt: 2 }}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            Delete
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

// DeleteFeeModal props for the DeleteUserModal
DeleteFeeModal.propTypes = {
  versionId: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default DeleteFeeModal;
