import _ from 'lodash';
import { useQuery } from 'react-query';
import { toastError } from 'utils/toast';

const { default: request } = require('utils/request');

const GET_TRANSACTION = '/bitmambo/admin/transactions';

const getUsers = (session_id) => {
  return request.get(`${GET_TRANSACTION}/${session_id}`);
};

// get transaction
export const useGetTransaction = (session_id) => {
  return useQuery([GET_TRANSACTION, session_id], () => getUsers(session_id), {
    onError: (error) => {
      const message = _.get(error, 'response.data.message[0]');
      toastError({ description: message });
    },
  });
};
