import React, { useState, useEffect } from 'react';

import { Stack } from '@mui/material';
import SoftBox from 'components/SoftBox';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import DataTable from 'rootComponents/Tables/DataTable';
import { set, get } from 'utils/localStorage';

import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import ResetUserModal from './components/ResetUserModal';
import SendSMS from './components/SendSMS';
import { useGetUsers } from './hooks';

const UsersManagement = ({ type }) => {
  const LC_PAYLOAD = `${type}Payload`;
  const [pageSize, setPageSize] = useState(10);
  const userPayload = get(LC_PAYLOAD) || {};

  // hooks
  const { mutate, isLoading, data } = useGetUsers();

  const mutateWithUserType = (payload) => {
    mutate({
      ...payload,
      type,
    });
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    const payload = userPayload;
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    mutateWithUserType(payload);
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    const payload = userPayload;
    payload.page = 1;
    payload.limit = pageSize;
    set(LC_PAYLOAD, payload);
    mutateWithUserType(payload);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, 'docs'), (item) => ({
      id: item.id,
      first_name: item.first_name,
      last_name: <b>{item.last_name}</b>,
      phone: item.phone,
      email: item.email,
      whatsapp_otp: item.whatsapp_otp,
      actions: (
        <SoftBox display='flex'>
          <DeleteUserModal user={item} refetch={refetch} type={type} />
          <ResetUserModal user={item} type={type} />
          <SendSMS phone={item?.phone} user_id={item?.id} />
        </SoftBox>
      ),
    }));
  };

  const refetch = () => {
    mutateWithUserType(userPayload);
  };

  // get users in init page
  useEffect(() => {
    if (userPayload) {
      mutateWithUserType(userPayload);
    } else {
      mutateWithUserType({
        page: 1,
        limit: pageSize,
      });
    }
  }, [LC_PAYLOAD, type]);

  return (
    <MainLayout>
      <MainNavbar />
      {type == 'admin' && (
        <Stack flexDirection={'row-reverse'} sx={{ mr: 3 }}>
          <AddUserModal refetch={refetch} />
        </Stack>
      )}

      <DataTable
        table={{
          columns: [
            { Header: 'email', accessor: 'email' },
            { Header: 'First Name', accessor: 'first_name' },
            { Header: 'last name', accessor: 'last_name' },
            { Header: 'phone', accessor: 'phone' },
            { Header: 'whatsapp otp', accessor: 'whatsapp_otp' },
            { Header: 'actions', accessor: 'actions' },
          ],
          rows: getRows(),
        }}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(data, 'pagination.totalPages', 0)}
        manualPagination={true}
        rowCount={_.get(data, 'pagination.totalDocs', 0)}
        isLoading={isLoading}
        isDownloadable={false}
        canSearch={true}
      />
    </MainLayout>
  );
};

// Typechecking props for the UsersManagement
UsersManagement.propTypes = {
  type: PropTypes.string.isRequired,
};
export default UsersManagement;
