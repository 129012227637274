import { useMemo, useEffect, useState } from 'react';

import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftPagination from 'components/SoftPagination';
import SoftSelect from 'components/SoftSelect';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from 'react-table';
import DataTableBodyCell from 'rootComponents/Tables/DataTable/DataTableBodyCell';
import DataTableHeadCell from 'rootComponents/Tables/DataTable/DataTableHeadCell';
import TableRowsLoader from 'rootComponents/Tables/DataTable/TableRowsLoader';

import TableDownloadCSV from './TableDownloadCSV';
import TableSelectColumnMenu from './TableSelectColumnMenu';

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  onPageSizeChange,
  onPageChange,
  pageCount,
  manualPagination,
  rowCount,
  isLoading,
  onCellClick,
  currentPage,
  columnsFilter,
  isDownloadable,
  dataToDownload,
  onDownloadClick,
  isServerDownload,
  filename,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries
    : [5, 10, 15, 20, 25];
  const columnsCP = table.columns;
  const [columns, setColumns] = useState(table.columns); //useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const manualPaginationSettings = manualPagination ? { pageCount } : {};

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination,
      autoResetPage: false,
      ...manualPaginationSettings,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => {
    setPageSize(defaultValue || 10);
    onPageSizeChange(defaultValue || 10);
  }, [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => {
    setPageSize(value);
    onPageSizeChange(value);
  };

  const handlePageChange = (option) => {
    gotoPage(option);
    onPageChange(option);
  };

  const handleGoPreviousPage = () => {
    if (currentPage) {
      gotoPage(currentPage - 1);
      onPageChange(currentPage - 2);
    } else {
      previousPage();
      onPageChange(pageIndex - 1);
    }
  };
  const handleGoNext = () => {
    if (currentPage) {
      gotoPage(currentPage + 1);
      onPageChange(currentPage);
    } else {
      nextPage();
      onPageChange(pageIndex + 1);
    }
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option) => {
    return (
      <SoftPagination
        item
        key={option}
        onClick={() => handlePageChange(Number(option))}
        active={currentPage ? currentPage === option + 1 : pageIndex === option}
      >
        {option + 1}
      </SoftPagination>
    );
  });

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? handlePageChange(0)
      : handlePageChange(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    handlePageChange(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // show/hide columns
  const handleColumnsChange = (value) => {
    setColumns(value);
  };

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = currentPage
    ? currentPage === 1
      ? currentPage
      : (currentPage - 1) * pageSize + 1
    : pageIndex === 0
    ? pageIndex + 1
    : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;
  if (currentPage) {
    if (currentPage === 1) {
      entriesEnd = pageSize;
    } else if (currentPage === pageOptions.length) {
      entriesEnd = rowCount;
    } else {
      entriesEnd = pageSize * currentPage;
    }
  } else {
    if (pageIndex === 0) {
      entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
      entriesEnd = rows.length;
    } else {
      entriesEnd = pageSize * (pageIndex + 1);
    }
  }

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <SoftBox
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          p={3}
        >
          {columnsFilter && (
            <TableSelectColumnMenu
              columns={columnsCP}
              onChange={handleColumnsChange}
            />
          )}
          {isDownloadable && dataToDownload?.length === 0 && (
            <TableDownloadCSV
              data={rows}
              headers={columns}
              useRowsData={true}
              onDownloadClick={onDownloadClick}
              filename={filename}
            />
          )}
          {isDownloadable && dataToDownload?.length !== 0 && (
            <TableDownloadCSV
              data={dataToDownload}
              headers={columns}
              useRowsData={false}
              isServerDownload={isServerDownload}
              onDownloadClick={onDownloadClick}
              filename={filename}
            />
          )}
          {entriesPerPage && (
            <SoftBox display='flex' alignItems='center'>
              <SoftSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({
                  value: entry,
                  label: entry,
                }))}
                onChange={setEntriesPerPage}
                size='small'
              />
              <SoftTypography variant='caption' color='secondary'>
                &nbsp;&nbsp;entries per page
              </SoftTypography>
            </SoftBox>
          )}
          {canSearch && (
            <SoftBox width='12rem' ml='auto'>
              <SoftInput
                placeholder='Search...'
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
      ) : null}
      <Table {...getTableProps()}>
        <SoftBox component='thead'>
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, key) => {
                if (!column.hide) {
                  return (
                    <DataTableHeadCell
                      key={key}
                      {...column.getHeaderProps(
                        isSorted && column.getSortByToggleProps()
                      )}
                      width={column.width ? column.width : 'auto'}
                      align={column.align ? column.align : 'left'}
                      sorted={setSortedValue(column)}
                    >
                      {column.render('Header')}
                    </DataTableHeadCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </SoftBox>
        <TableBody {...getTableBodyProps()}>
          {isLoading ? (
            <TableRowsLoader rowsNum={pageSize} colsNum={columns.length} />
          ) : (
            page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow
                  key={key}
                  {...row.getRowProps()}
                  onClick={() => onCellClick(row)}
                >
                  {row.cells.map((cell, key) => {
                    if (!cell.column.hide)
                      return (
                        <DataTableBodyCell
                          key={key}
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : 'left'}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </DataTableBodyCell>
                      );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      <SoftBox
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent='space-between'
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SoftBox mb={{ xs: 3, sm: 0 }}>
            <SoftTypography
              variant='button'
              color='secondary'
              fontWeight='regular'
            >
              Showing {entriesStart} to {entriesEnd} of{' '}
              {manualPagination ? rowCount : rows.length} entries
            </SoftTypography>
          </SoftBox>
        )}
        {pageOptions.length > 1 && (
          <SoftPagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}
          >
            {(canPreviousPage || currentPage > 1) && (
              <SoftPagination item onClick={() => handleGoPreviousPage()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </SoftPagination>
            )}
            {renderPagination.length > 6 ? (
              <SoftBox width='5rem' mx={1}>
                <SoftInput
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={
                    currentPage
                      ? customizedPageOptions[currentPage - 1]
                      : customizedPageOptions[pageIndex]
                  }
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SoftBox>
            ) : (
              renderPagination
            )}
            {(currentPage
              ? renderPagination.length !== currentPage
              : canNextPage) && (
              <SoftPagination item onClick={() => handleGoNext()}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </SoftPagination>
            )}
          </SoftPagination>
        )}
      </SoftBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  onPageSizeChange: () => void 0,
  onPageChange: () => void 0,
  pageCount: 1,
  manualPagination: false,
  rowCount: 0,
  isLoading: false,
  onCellClick: () => void 0,
  columnsFilter: false,
  isDownloadable: false,
  dataToDownload: [],
  onDownloadClick: () => void 0,
  isServerDownload: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number,
  manualPagination: PropTypes.bool,
  rowCount: PropTypes.number,
  isLoading: PropTypes.bool,
  onCellClick: PropTypes.func,
  columnsFilter: PropTypes.bool,
  isDownloadable: PropTypes.bool,
  dataToDownload: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  onDownloadClick: PropTypes.func,
  isServerDownload: PropTypes.bool,
  filename: PropTypes.string,
};

export default DataTable;

