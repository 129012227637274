import { StatusColorEnum } from "constants/status";

import { forwardRef } from "react";

import { Chip } from "@mui/material";
import PropTypes from "prop-types";


const StatusChip = forwardRef(({ label, ...rest }, ref) => {
  const color = StatusColorEnum[label];
  return <Chip label={label} {...rest} ref={ref} color={color} />;
});
// Setting default values for the props of SoftPagination
StatusChip.defaultProps = {
  label: "",
};

// Typechecking props for the SoftPagination
StatusChip.propTypes = {
  label: PropTypes.string,
};
export default StatusChip;
