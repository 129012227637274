import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MiniVerificationCard from 'rootComponents/Cards/StatisticsCards/MiniVerificationCard';

// This code gets the transaction detail from the API and renders it as a tree view.
const Verification = ({ data }) => {
  const isMatched =
    _.get(data, 'verification.request_amount', 0) ==
    _.get(data, 'verification.paid_amount', 0);

  const approveTransaction = () => {
    window.open('https://my.vaultody.com/transactions-requests');
  };

  return (
    <Card>
      <SoftBox bgColor={'white'} variant='gradient'>
        <SoftBox pt={3} px={3}>
          <SoftTypography variant='h6' fontWeight='medium' color={'dark'}>
            Verification
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={5} md={5} lg={5}>
              <MiniVerificationCard
                bgColor='secondary'
                title={{
                  text: 'REQUESTED',
                  fontWeight: 'medium',
                }}
                amount={_.get(data, 'verification.request_amount', 0)}
                currency={{
                  text: _.get(data, 'verification.request_currency', 'N/A'),
                  color: 'success',
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <SoftBox
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                sx={{ height: '100%' }}
              >
                <SoftTypography
                  variant='caption'
                  fontWeight='bold'
                  color={isMatched ? 'success' : 'error'}
                >
                  MATCH
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={5} md={5} lg={5}>
              <MiniVerificationCard
                bgColor='info'
                title={{
                  text: 'PAID',
                  fontWeight: 'medium',
                }}
                amount={_.get(data, 'verification.paid_amount', 0)}
                currency={{
                  text: _.get(data, 'verification.paid_currency', 'N/A'),
                  color: 'success',
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <SoftBox mt={4} mb={1}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  size='large'
                  fullWidth
                  onClick={approveTransaction}
                  disabled={!isMatched}
                >
                  Approve Transaction
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
Verification.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Verification;
