/* eslint-disable import/order */
import { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';

const DatePickerField = forwardRef(
  ({ options, input, name, label, error, message, ...rest }, ref) => {
    const { control } = useFormContext();

    return (
      <SoftBox
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        height='100%'
        mb={1.5}
      >
        {label && (
          <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
            <SoftTypography
              component='label'
              variant='caption'
              fontWeight='bold'
              textTransform='capitalize'
            >
              {label}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox mb={1} ml={0.5} lineHeight={0}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Flatpickr
                {...field}
                options={options}
                onChange={(selectedDates) => {
                  field.onChange(selectedDates[0]);
                }}
                render={({ defaultValue }, ref) => (
                  <SoftInput {...input} defaultValue={null} inputRef={ref} />
                )}
              />
            )}
          />
        </SoftBox>
        {error && message && (
          <SoftBox mt={0.75}>
            <SoftTypography component='div' variant='caption' color='error'>
              {message}
            </SoftTypography>
          </SoftBox>
        )}
      </SoftBox>
    );
  }
);

// Setting default values for the props of SoftInput
DatePickerField.defaultProps = {
  label: '',
  message: '',
  error: false,
  input: {},
  options: {},
};

// Typechecking props for the SoftInput
DatePickerField.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  input: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DatePickerField;
