import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import StatusChip from 'components/StatusChip';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fDateTimeSuffix } from 'utils/formatTime';

// This code gets the transaction detail from the API and renders it as a tree view.
const TransactionSummary = ({ data }) => {
  return (
    <Card id='basic-info' sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant='h5'>Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox lineHeight={3}>
              <SoftTypography variant='caption' color='text'>
                Session ID:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'transaction.id', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <SoftTypography variant='caption' color='text'>
                Status:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  <StatusChip
                    label={_.get(data, 'transaction.status', 'N/A')}
                    size='small'
                  />
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={3}>
              <SoftTypography variant='caption' color='text'>
                Kasha Session ID:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'transaction.external_id', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                Kasha Transaction ID:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'transaction.external_transaction_id', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                Created Date:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {fDateTimeSuffix(
                    _.get(data, 'transaction.created_date', null)
                  )}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                Updated Date:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {fDateTimeSuffix(
                    _.get(data, 'transaction.updated_date', null)
                  )}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
};
// TransactionSunmary props for the SummaryItem
TransactionSummary.propTypes = {
  data: PropTypes.object.isRequired,
};
export default TransactionSummary;
