import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DoneMessage from './DoneMessage';
import ErrorMessage from './ErrorMessage';
import NoVerification from './NoVerification';
import PaymentMethod from './PaymentMethod';
import TransactionProgress from './TransactionProgress';
import Verification from './Verification';
// This code gets the transaction detail from the API and renders it as a tree view.
const PaymentDetails = ({ data }) => {
  return (
    <Card id='basic-info' sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant='h5'>Payment Details</SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TransactionProgress data={data} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PaymentMethod data={data} />
            {data?.transaction?.status != 'ERROR' &&
              data?.transaction?.status != 'DONE' &&
              data?.verification && <Verification data={data} />}
            {data?.transaction?.status != 'ERROR' && !data?.verification && (
              <NoVerification />
            )}
            {data?.transaction?.status == 'ERROR' && (
              <ErrorMessage error={data?.transaction?.error_msg} />
            )}
            {data?.transaction?.status == 'DONE' && (
              <DoneMessage
                url={_.get(data, 'transaction.blockchain_explorer_url', '')}
              />
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
};
PaymentDetails.propTypes = {
  data: PropTypes.object.isRequired,
};
export default PaymentDetails;
