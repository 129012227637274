import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  need_approvals: [],
};

export const schema = Yup.object().shape({
  need_approvals: Yup.array(
    Yup.object().shape({
      token: Yup.string().required('token is required'),
      amount: Yup.number().min(0).required('limit amount is required'),
    })
  ),
});

export const sanitizeRequest = ({ need_approvals }) => {
  const payload = _.pickBy(
    {
      need_approvals,
    },
    _.identity
  );
  return payload;
};
