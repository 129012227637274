import React from 'react';

import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <SoftBox sx={{ p: 3 }}>
          <SoftTypography>{children}</SoftTypography>
        </SoftBox>
      )}
    </div>
  );
}
export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
export default TabPanel;
