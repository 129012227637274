import colors from 'assets/theme/base/colors';

const { dark } = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};

export default formLabel;

