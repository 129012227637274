import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  referral: {
    reward_type: '',
    reward_unit: '',
    reward_amount: 0,
  },
};

export const schema = Yup.object().shape({
  referral: Yup.object().shape({
    reward_type: Yup.string().required('reward type is required'),
    reward_unit: Yup.string().required('reward unit is required'),
    reward_amount: Yup.number().required('reward amount is required'),
  }),
});

export const sanitizeRequest = ({ referral }) => {
  const payload = _.pickBy(
    {
      referral,
    },
    _.identity
  );
  return payload;
};
