import { Stack, Icon } from '@mui/material';
import SoftBadge from 'components/SoftBadge';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'rootComponents/LayoutContainers/MainLayout';
import MainNavbar from 'rootComponents/Navbars/MainNavbar';
import DataTable from 'rootComponents/Tables/DataTable';
import { fDateTimeSuffix } from 'utils/formatTime';

import AddVersionModal from './components/AddVersionModal';
import Badges from './components/Badges';
import DeleteVersionModal from './components/DeleteVersionModal';
import RenderCurrencies from './components/RenderCurrencies';
import { useGetVersions } from './hooks';

const Versions = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useGetVersions();

  // create a function that navigates to the detail page for a given transaction
  const handleDetailClick = (id) => {
    navigate(`/settings/versions/${id}`);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(data, (item) => {
      return {
        id: item.id,
        name: item.name,
        currency: item.currency,
        created_date: fDateTimeSuffix(item.created_date),
        currencies: <RenderCurrencies limits={item.limits} />,
        payment_methods: <Badges labels={item.payment_methods} />,
        active: (
          <SoftBadge
            sx={{ mr: 1 }}
            size='xs'
            color={item.enabled ? 'success' : 'error'}
            badgeContent={item.enabled ? 'enabled' : 'disabled'}
            container
          />
        ),
        actions: (
          <SoftBox display='flex'>
            <SoftBox>
              <SoftButton
                variant='text'
                color='dark'
                onClick={() => handleDetailClick(item.id)}
              >
                <Icon>visibility</Icon>
              </SoftButton>
            </SoftBox>
            <DeleteVersionModal version={item} display='icon' />
          </SoftBox>
        ),
      };
    });
  };

  return (
    <MainLayout>
      <MainNavbar />
      <Stack flexDirection={'row-reverse'} sx={{ mr: 3 }}>
        <AddVersionModal />
      </Stack>
      <DataTable
        table={{
          columns: [
            { Header: 'name', accessor: 'name' },
            { Header: 'created time', accessor: 'created_date' },
            { Header: 'currencies', accessor: 'currencies' },
            { Header: 'payment methods', accessor: 'payment_methods' },
            { Header: 'active', accessor: 'active' },
            { Header: 'actions', accessor: 'actions' },
          ],
          rows: getRows(),
        }}
        isLoading={isLoading}
      />
    </MainLayout>
  );
};

export default Versions;

