import _ from 'lodash';
import { useMutation } from 'react-query';
import { removeToken } from 'utils/cache';
import { setToken } from 'utils/cache';
import request from 'utils/request';
const SIGN_IN_PATH = '/user/lite/login';

const signIn = ({ email, password }) => {
  return request.post(SIGN_IN_PATH, {
    email,
    password,
    type: 'admin',
  });
};

export const useSignIn = () => {
  return useMutation(signIn, {
    onSuccess: (res) => {
      const accessToken = _.get(res, 'auth_token');
      setToken(accessToken);
      window.location.href = '/';
    },
  });
};

export const useLogout = () => {
  const handleLogout = () => {
    removeToken();
    window.location.href = '/sign-in';
  };

  return {
    logout: handleLogout,
  };
};

