import { create } from 'zustand';

type CurrentVersion = {
  id: string | null;
  name: string | null;
  enabled: boolean | null;
};

type VersionStore = {
  currentVersionState: CurrentVersion;
  setCurrentVersion: (version: CurrentVersion) => void;
};

const initialState: VersionStore = {
  currentVersionState: {
    id: null,
    name: null,
    enabled: null,
  },
  setCurrentVersion: () => null,
};

export const useVersionStore = create((set) => ({
  ...initialState,
  setCurrentVersion: (version: CurrentVersion) => {
    return set(({ currentVersionState }) => ({
      currentVersionState: { ...currentVersionState, ...version },
    }));
  },
}));

