import { useEffect } from 'react';

import SoftBox from 'components/SoftBox';
import { useSoftUIController, setLayout } from 'context';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

function PageLayout({ background, children }) {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname]);

  return (
    <SoftBox
      width='100vw'
      height='100%'
      minHeight='100vh'
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </SoftBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default PageLayout;

