import { networks, networkFeesUnits } from 'constants/networks';

import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Stack, DialogActions } from '@mui/material';
import { Grid, Box, Divider, IconButton, Icon } from '@mui/material';
import {
  TextInputField,
  SelectInputField,
  CountrySelect,
} from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useVersionStore } from 'stores/version';

import { useGetFee, useUpdateFee } from '../hooks';
import { schema, defaultValues, sanitizeVersionRequest } from '../schemas/fee';
import DeleteFeeModal from './DeleteFeeModal';

const FeesTab = () => {
  const { id } = useParams();

  const {
    currentVersionState: { name: versionName },
  } = useVersionStore();

  // Hooks
  const { data, isError, refetch } = useGetFee({ id });
  const { mutate: updateFee, isLoading } = useUpdateFee({ id });

  // Forms
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, reset, control } = methods;
  const {
    fields: countryFees,
    append: appendCountryFee,
    remove: removeCountryFee,
  } = useFieldArray({ control, name: 'country_fees' });
  const {
    fields: networkFees,
    append: appendNetworkFee,
    remove: removeNetworkFee,
  } = useFieldArray({ control, name: 'tx_fees' });

  const onSubmit = (values) => {
    const data = sanitizeVersionRequest({ ...values, id });
    updateFee(data);
  };
  const unitOptions = [{ label: 'Percentage %', value: 'percentage' }];

  useEffect(() => {
    if (data) {
      reset(data);
    }
    if (isError) {
      reset(defaultValues);
    }
  }, [data, isError]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={1} sm={1} />
      <Grid item xs={10} sm={10}>
        <Card sx={{ overflow: 'visible' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Service Fees
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                manage/update service fees settings for{' '}
                <b>{versionName || id}</b>
              </SoftTypography>
            </Stack>
            <Stack m={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextInputField label='Fee' name='service_fee.fee' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInputField
                    options={unitOptions}
                    label={`Fee Unit`}
                    name={`service_fee.unit`}
                  />
                </Grid>
              </Grid>
              <Divider
                textAlign='left'
                sx={{
                  opacity: 1,
                  backgroundImage: 'none !important',
                  height: 'auto',
                }}
              >
                <SoftTypography variant='caption'>
                  Country Fee settings
                </SoftTypography>
              </Divider>
              <SoftBox ml={6}>
                {_.map(countryFees, (field, index) => (
                  <Grid container key={field.id}>
                    <Grid item xs={10.5}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <CountrySelect
                              label='Country'
                              name={`country_fees[${index}].country`}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6} lg={6}>
                            <TextInputField
                              required
                              label={`Fee`}
                              name={`country_fees[${index}].service_fee.fee`}
                            />
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <SelectInputField
                              options={unitOptions}
                              label={`Fee Unit`}
                              name={`country_fees[${index}].service_fee.unit`}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => removeCountryFee(index)}
                        sx={{ mt: 2 }}
                      >
                        <Icon>delete_forever</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <SoftButton
                  sx={{ mt: 2 }}
                  onClick={() =>
                    appendCountryFee({ currency: '', min: 0, max: 0 })
                  }
                >
                  Add Country Fee
                </SoftButton>
              </SoftBox>
            </Stack>
            <Stack lineHeight={0} m={3}>
              <SoftTypography variant='h5' fontWeight='bold'>
                Network Fees
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='text'
              >
                manage/update network fees settings for{' '}
                <b>{versionName || id}</b>
              </SoftTypography>
            </Stack>
            <Stack m={3}>
              <SoftBox ml={6}>
                {_.map(networkFees, (field, index) => (
                  <Grid container key={field.id}>
                    <Grid item xs={10.5}>
                      <Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <SelectInputField
                              options={networks}
                              label={`Network`}
                              name={`tx_fees[${index}].network`}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6} lg={6}>
                            <TextInputField
                              required
                              label={`Fee`}
                              name={`tx_fees[${index}].fee`}
                            />
                          </Grid>
                          <Grid item xs={6} lg={6}>
                            <SelectInputField
                              options={networkFeesUnits}
                              label={`Network Fee Unit`}
                              name={`tx_fees[${index}].unit`}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => removeNetworkFee(index)}
                        sx={{ mt: 2 }}
                      >
                        <Icon>delete_forever</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <SoftButton
                  sx={{ mt: 2 }}
                  onClick={() =>
                    appendNetworkFee({ currency: '', min: 0, max: 0 })
                  }
                >
                  Add Network Fee
                </SoftButton>
              </SoftBox>
            </Stack>
            <Stack ml={1}>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <SoftButton
                  variant='gradient'
                  color='info'
                  type='submit'
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Update
                </SoftButton>
                <DeleteFeeModal
                  version={versionName}
                  versionId={id}
                  refetch={refetch}
                />
              </DialogActions>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FeesTab;
