import _ from 'lodash';
import PropTypes from 'prop-types';

import Badges from './Badges';

const RenderCurrencies = ({ limits }) => {
  const currencies = _.map(limits, (limit) => {
    return limit.currency;
  });
  return <Badges labels={currencies} />;
};
RenderCurrencies.propTypes = {
  item: PropTypes.Object,
};

export default RenderCurrencies;
