import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

function MiniVerificationCard({
  bgColor,
  title,
  amount,
  currency,
  onClick,
  selected = false,
}) {
  return (
    <Card
      onClick={onClick}
      sx={{
        border: selected ? '2px solid' : 'none',
        borderColor: 'secondary.main',
      }}
    >
      <SoftBox bgColor={bgColor} variant='gradient'>
        <SoftBox p={2}>
          <Grid container alignItems='center'>
            <Grid item xs={12}>
              <SoftBox ml={0} lineHeight={1}>
                <SoftTypography
                  variant='button'
                  color={bgColor === 'white' ? 'text' : 'white'}
                  opacity={bgColor === 'white' ? 1 : 0.7}
                  textTransform='capitalize'
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </SoftTypography>
                <SoftTypography
                  variant='h5'
                  fontWeight='bold'
                  color={bgColor === 'white' ? 'dark' : 'white'}
                >
                  {amount}{' '}
                  <SoftTypography
                    variant='button'
                    color={currency.color}
                    fontWeight='bold'
                  >
                    {currency.text}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniVerificationCard.defaultProps = {
  bgColor: 'white',
  title: {
    fontWeight: 'medium',
    text: '',
  },
  currency: {
    color: 'success',
    text: '',
  },
};

// Typechecking props for the MiniStatisticsCard
MiniVerificationCard.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  bgColor: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string,
  }),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default MiniVerificationCard;

