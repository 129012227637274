import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MiniVerificationCard from 'rootComponents/Cards/StatisticsCards/MiniVerificationCard';

// This code gets the transaction detail from the API and renders it as a tree view.
const ErrorMessage = ({ error }) => {
  return (
    <Card>
      <SoftBox bgColor={'white'} variant='gradient'>
        <SoftBox pt={3} px={3}>
          <SoftTypography variant='h6' fontWeight='medium' color={'dark'}>
            Transaction Error
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MiniVerificationCard
                bgColor='error'
                title={{
                  text: `${error}`,
                  fontWeight: 'medium',
                }}
                amount={''}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorMessage;
