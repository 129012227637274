import { statusIcon, StatusColorEnum } from 'constants/status';

import { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { getStatusReport } from 'utils/analytic';

import SummaryItem from './SummaryItem';

function StatusSummary({ data }) {
  const [statusReportData, setStatusReportData] = useState({});
  const [totalStatusCount, setTotalStatusCount] = useState(0);
  useEffect(() => {
    const reportData = getStatusReport(data);
    setTotalStatusCount(_.sum(Object.values(reportData)));
    setStatusReportData(reportData);
  }, [data]);
  return (
    <Card sx={{ height: '100%' }}>
      <SoftBox
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        pt={2}
        px={2}
      >
        <SoftTypography variant='h6'>Status</SoftTypography>
        <Tooltip title='Status count' placement='bottom'>
          <SoftButton
            variant='outlined'
            color='secondary'
            size='small'
            circular
            iconOnly
          >
            <Icon>priority_high</Icon>
          </SoftButton>
        </Tooltip>
      </SoftBox>
      <SoftBox p={2}>
        {_.map(Object.keys(statusReportData), (item, key) => {
          return (
            <SummaryItem
              key={key}
              icon={{
                color: StatusColorEnum[item],
                component: <Icon>{statusIcon[item]}</Icon>,
              }}
              title={item}
              amount={statusReportData[item]}
              total={totalStatusCount}
            />
          );
        })}
      </SoftBox>
    </Card>
  );
}
StatusSummary.propTypes = {
  data: PropTypes.array.isRequired,
};
export default StatusSummary;
