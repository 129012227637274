export const StatusEnum = {
  INIT: 'INIT',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED',
};

export const StatusColorEnum = {
  INIT: 'default',
  PENDING: 'secondary',
  PROCESSING: 'primary',
  DONE: 'success',
  ERROR: 'error',
  DECLINED: 'error',
  EXPIRED: 'error',
};

export const ActiveStatusColorEnum = {
  true: 'success',
  false: 'error',
};

export const ActiveStatusEnum = {
  true: 'Active',
  false: 'Inactive',
};

export const statusIcon = {
  PENDING: 'pending',
  SUCCESS: 'check_circle',
  FAILED: 'cancel',
  ERROR: 'error',
  DECLINED: 'cancel',
  INIT: 'not_started',
  EXPIRED: 'error',
  PROCESSING: 'refresh',
  DONE: 'check_circle',
};
