import SoftBadge from 'components/SoftBadge';
import _ from 'lodash';
import PropTypes from 'prop-types';

const colors = ['success', 'warning', 'error', 'info', 'primary', 'secondary'];

const Badges = ({ labels }) => {
  return (
    <>
      {_.map(labels, (label, index) => {
        const color = colors[index];
        return (
          <SoftBadge
            sx={{ mr: 1 }}
            size='xs'
            color={'info'}
            badgeContent={label}
            container
            key={label}
          />
        );
      })}
    </>
  );
};
Badges.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
};
export default Badges;
