import { Card } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MasterCard from 'rootComponents/Cards/MasterCard';
import ComplexPhonePaymentCard from 'rootComponents/Cards/StatisticsCards/ComplexPhonePaymentCard';

// This code gets the transaction detail from the API and renders it as a tree view.
const PaymentMethod = ({ data }) => {
  return (
    <Card>
      <SoftBox bgColor={'white'} variant='gradient'>
        <SoftBox pt={3} px={3}>
          <SoftTypography variant='h6' fontWeight='medium' color={'dark'}>
            Payment method
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          {data?.transaction?.payment_details?.card && (
            <MasterCard
              number={_.get(
                data,
                'transaction.payment_details.card.card_number',
                'N/A'
              )}
              holder={_.get(
                data,
                'transaction.payment_details.card.card_holder_name',
                'N/A'
              )}
              expires={_.get(
                data,
                'transaction.payment_details.card.expiration_date',
                'N/A'
              )}
            />
          )}
          {data?.transaction?.payment_details?.mobile && (
            <ComplexPhonePaymentCard
              phoneNumber={_.get(
                data,
                'transaction.payment_details.mobile.phone_number',
                'N/A'
              )}
              voucher={_.get(
                data,
                'transaction.payment_details.mobile.voucher',
                'N/A'
              )}
            />
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
PaymentMethod.propTypes = {
  data: PropTypes.object.isRequired,
};
export default PaymentMethod;
