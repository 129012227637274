import { currenciesFlags } from 'constants/currenciesFlags';

import _ from 'lodash';

//Generates an array of random colors in HEX format.
const generateRandomColors = (count) => {
  const colors = [];
  const getRandomHex = () => {
    const hexDigits = '0123456789ABCDEF';
    let hex = '#';
    for (let i = 0; i < 6; i++) {
      hex += hexDigits[Math.floor(Math.random() * 16)]; // Generate a random HEX digit for each character in the color (6 characters)
    }
    return hex;
  };
  // Generate the specified number of random colors
  for (let i = 0; i < count; i++) {
    const color = getRandomHex();
    colors.push(color);
  }

  return colors;
};
const getRandomColor = (colors) => {
  var randomIndex = Math.floor(Math.random() * colors.length);
  var color = colors[randomIndex];
  return color;
};
const generateChartColors = (cArr) => {
  var colorList = [];
  while (colorList.length < Object.keys(cArr).length) {
    var randomColor = getRandomColor(
      generateRandomColors(Object.keys(cArr).length)
    );
    if (!colorList.includes(randomColor)) {
      colorList.push(randomColor);
    }
  }
  return colorList;
};

// Function to calculate the percentage values based on the provided data
const percentageValue = (cArr) => {
  const total = _.sum(Object.values(cArr)); // Calculate the total sum of the data array
  const percentage = _.map(cArr, (item) => ((item / total) * 100).toFixed(2)); // Calculate the percentage for each item in the data array
  return percentage;
};

const amountGroupByCurrency = (data, currencyType) => {
  const amountByCurrency = {};
  _.map(data, (item) => {
    const currencyCode = _.get(
      item,
      `payment_details.${currencyType}_currency`
    );
    const amount = _.get(item, `payment_details.${currencyType}_amount`, 0);
    if (amountByCurrency[currencyCode]) {
      amountByCurrency[currencyCode] += amount;
    } else {
      amountByCurrency[currencyCode] = amount;
    }
  });
  return amountByCurrency;
};

// Function to generate a report grouped by country, with additional data for chart visualization
export const amountGroupByFromCurrencyReport = (data) => {
  const reportData = amountGroupByCurrency(data, 'from'); // Generate the report data grouped by country
  const currenciesCodes = Object.keys(reportData); // Get an array of country codes from the report data

  const images = currenciesCodes?.map((code) =>
    _.get(currenciesFlags, `${code.toLowerCase()}`, '')
  ); // Get an array of country images based on the country codes
  const labels = currenciesCodes;
  const complexReportsDoughnutChartData = {
    images: images,
    labels: labels,
    datasets: {
      label: 'Summary By Currency',
      backgroundColors: generateChartColors(reportData),
      data: percentageValue(reportData),
      allData: reportData,
    },
  }; // Prepare the complexReportsDoughnutChartData object for chart visualization
  return complexReportsDoughnutChartData;
};

export const amountGroupByStatus = (data) => {
  const statusSums = {};

  data?.forEach((item) => {
    const status = item.status;
    if (statusSums[status]) {
      statusSums[status] += 1;
    } else {
      statusSums[status] = 1;
    }
  });

  return statusSums;
};

export const getStatusReport = (data) => {
  const reportData = amountGroupByStatus(data);
  return reportData;
};
