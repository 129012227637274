import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  role: null,
};

export const schema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is required')
    .max(100, 'First name is too long, should be less than 100 characters'),
  last_name: Yup.string()
    .required('Last name is required')
    .max(100, 'Last name is too long, should be under 100 characters'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  role: Yup.string().required('Role is required'),
});

export const sanitizeRequest = ({
  first_name,
  last_name,
  role,
  phone,
  email,
}) => {
  return _.pickBy(
    {
      first_name,
      last_name,
      role,
      phone,
      email,
      username: email,
    },
    _.identity
  );
};
