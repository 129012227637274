import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import whiteCurved from 'assets/images/curved-images/white-curved.jpeg';
import mobilePayment from 'assets/images/phone-payment.png';
import typography from 'assets/theme/base/typography';
import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

function ComplexPhonePaymentCard({
  color,
  icon,
  phoneNumber,
  voucher,
  percentage,
  dropdown,
}) {
  const { size } = typography;

  return (
    <Card
      sx={({
        functions: { linearGradient, rgba },
        palette: { gradients },
      }) => ({
        background: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.9),
              rgba(gradients[color].state, 0.9)
            )}, url(${whiteCurved})`
          : `${linearGradient(
              rgba(gradients.dark.main, 0.9),
              rgba(gradients.dark.state, 0.9)
            )}, url(${whiteCurved})`,
      })}
    >
      <SoftBox p={2}>
        <Grid container>
          <Grid item xs={8}>
            <SoftBox display='flex' alignItems='center'>
              <SoftAvatar
                src={mobilePayment}
                alt={'mobile-payment'}
                size='md'
                variant='rounded'
                bgColor={'light'}
                sx={{ p: 1 }}
              />
              <SoftBox ml={2} lineHeight={0}>
                <SoftBox mb={1} lineHeight={0}>
                  <SoftTypography
                    variant='h6'
                    textTransform='capitalize'
                    fontWeight='medium'
                    color='white'
                  >
                    Mobile Payment
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox mt={2} lineHeight={0}>
              <SoftTypography variant='h5' fontWeight='bold' color='white'>
                Phone: {phoneNumber}
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='regular'
                color='white'
                textTransform='capitalize'
              >
                Voucher: {voucher}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexPhonePaymentCard.defaultProps = {
  color: 'dark',
  dropdown: false,
  voucher: 'N/A',
};

// Typechecking props for the ComplexStatisticsCard
ComplexPhonePaymentCard.propTypes = {
  voucher: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  percentage: PropTypes.string.isRequired,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexPhonePaymentCard;

