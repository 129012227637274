import { StatusEnum } from 'constants/status';

import React, { useEffect, useState } from 'react';

import { Card, Grid } from '@mui/material';
import {
  TextInputField,
  SelectInputField,
  DatePickerField,
} from 'components/FormField';
import { CurrencySelect } from 'components/FormField';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { extractFromCurrencies } from 'utils/string';

import { useGetPairs } from '../useTransactions';

const TransactionFilters = () => {
  const { watch, setValue } = useFormContext();
  const watchFromCurrency = watch('from_currency');

  const [toCOptoins, setToCOptoins] = useState([]);

  const { data: pairs } = useGetPairs();
  const fromCurrencies = extractFromCurrencies(pairs);

  const now = new Date();
  const dateTimePickerOptions = {
    allowInput: false,
    enableTime: true,
    maxDate: now,
    maxTime: now.getTime(),
  };
  const methodOptions = [
    { label: 'Mobile', value: 'mobile' },
    { label: 'Card', value: 'card' },
  ];
  const statusOptions = [{ value: '', label: 'All' }].concat(
    _.map(StatusEnum, (item) => ({ value: item, label: item }))
  );

  // Set to Currency options when from currency is selected
  useEffect(() => {
    if (!watchFromCurrency) return;
    const tcOptions = pairs[watchFromCurrency].map((pair) => {
      return { label: pair.short_name, value: pair.short_name };
    });
    setValue('to_currency', null);
    setToCOptoins(tcOptions);
  }, [watchFromCurrency]);

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: 'visible' }}>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <TextInputField label='Session ID' name='session_id' />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextInputField label='User ID' name='user_id' />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <TextInputField label='Email' name='email' />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextInputField label='Phone number' name='phone' />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CurrencySelect
                      name='from_currency'
                      label='From Currency'
                      message='Required'
                      isClearable
                      currencies={fromCurrencies}
                      placeholder='Select from currency'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField
                      name='to_currency'
                      label='To Currency'
                      options={toCOptoins}
                      placeholder='Select to currency'
                      isClearable
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField
                      name='payment_method'
                      label='Payment Method'
                      message='Required'
                      isClearable
                      options={methodOptions}
                      placeholder='Select payment method'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInputField
                      name='status'
                      label='Status'
                      options={statusOptions}
                      placeholder='Select an status'
                      isClearable
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInputField
                      label='Min Amount'
                      name='minAmount'
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInputField
                      label='Max Amount'
                      name='maxAmount'
                      type='number'
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      name='fromDate'
                      options={dateTimePickerOptions}
                      label='From Date'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      name='toDate'
                      options={dateTimePickerOptions}
                      label='To Date'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container display='flex' justifyContent='space-between'>
              <SoftButton
                variant='gradient'
                color='info'
                type='submit'
                sx={{ mt: 2 }}
              >
                Search
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TransactionFilters;

