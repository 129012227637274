import { useEffect, useState } from 'react';

import TextsmsIcon from '@mui/icons-material/Textsms';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@mui/material';
import pxToRem from 'assets/theme/functions/pxToRem';
import { TextInputField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { useDisclosure } from 'hooks/useDisclosure';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toastError } from 'utils/toast';

import { useGenerateWhatsappOtp, useSendWhatsappOtp } from '../hooks';

const SendSMS = ({ phone, user_id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [message, setMessage] = useState('');
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading: isGenerating, mutate } = useGenerateWhatsappOtp();
  const { mutate: sendOtp, isLoading } = useSendWhatsappOtp();

  // create a function that handles the form submission
  const onSubmit = () => {
    if (_.isEmpty(message)) {
      toastError({ description: 'Please generate a password' });
      return;
    }
    sendOtp({ user_id }, { onSuccess: onClose });
  };

  const generateOtp = () => {
    mutate(
      {
        user_id,
      },
      {
        onSuccess: (data) => {
          setMessage(`Your Bitmambo verification code is ${data}`);
        },
      }
    );
  };

  return (
    <>
      <SoftBox>
        <SoftButton variant='text' color='dark' onClick={onOpen}>
          <Icon>sms</Icon>
        </SoftButton>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='xs'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Send SMS
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox component='form' role='form'>
                <Grid container spacing={3}>
                  <Grid item xs={2} sm={2}>
                    <SoftBox display='flex' alignItems='center'>
                      <TextsmsIcon
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                    </SoftBox>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <SoftBox ml={2}>
                      <SoftTypography variant='h6' fontWeight='medium'>
                        To: {phone}
                      </SoftTypography>

                      <SoftBox mt={2}>
                        <SoftButton
                          variant='gradient'
                          color='secondary'
                          onClick={generateOtp}
                          size={'small'}
                          isLoading={isGenerating}
                        >
                          Generate new password
                        </SoftButton>
                        <TextInputField
                          sx={{ mt: 1 }}
                          name='sms'
                          multiline={true}
                          minRows={5}
                          readOnly
                          value={message}
                        />
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant='gradient' color='dark' onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant='gradient'
              color='info'
              type='submit'
              disabled={isLoading}
              isLoading={isLoading}
            >
              Send
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the ResetUserModal
SendSMS.propTypes = {
  phone: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
};

export default SendSMS;
