import { forwardRef } from "react";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import _ from "lodash";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";


const TextInputField = forwardRef(({ name, label, ...rest }, ref) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            height="100%"
            mb={1.5}
          >
            {label && (
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {label}
                </SoftTypography>
              </SoftBox>
            )}
            <SoftInput {...field} {...rest} ref={ref} />
            {error && (
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  {_.get(error, "message", "Unknown error")}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        );
      }}
    />
  );
});

// Setting default values for the props of SoftInput
TextInputField.defaultProps = {
  label: "",
  error: false,
};

// Typechecking props for the SoftInput
TextInputField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default TextInputField;
