import _ from 'lodash';
import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { getQueryParam } from 'utils/string';
import { toastError } from 'utils/toast';

import { TransactionPayload } from './types';

const GET_TRANSACTIONS = '/bitmambo/admin/transactions/';
const GET_PAIRS = '/currency/assets/pairs?version=Africa&country=NG';

const getPairs = () => request.post(GET_PAIRS, {});
const getTransactions = async (payload: TransactionPayload): Promise<any> => {
  const queryParams = getQueryParam(payload);

  return request.get(`${GET_TRANSACTIONS}?${queryParams}`);
};

export const useGetTransactions = () => {
  return useMutation(getTransactions, {
    onError: (error: any) => {
      const message = _.get(error, 'response.data.message[0]');
      toastError({ description: message });
    },
  });
};

export const useGetPairs = () => {
  return useQuery([GET_PAIRS], getPairs);
};

