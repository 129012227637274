import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@mui/material';
import pxToRem from 'assets/theme/functions/pxToRem';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { useDisclosure } from 'hooks/useDisclosure';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { useDeleteVersion } from '../hooks';

const DeleteVersionModal = ({ version, display }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading, mutate } = useDeleteVersion();

  // create a function that handles the form submission
  const onSubmit = () => {
    mutate(
      { id: version.id },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <SoftBox>
        {display === 'button' && (
          <SoftButton
            variant='gradient'
            color='error'
            sx={{ ml: 2 }}
            onClick={onOpen}
          >
            Delete
          </SoftButton>
        )}
        {display === 'icon' && (
          <SoftButton variant='text' color='dark' onClick={onOpen}>
            <Icon sx={{ color: 'red' }}>delete</Icon>
          </SoftButton>
        )}
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='xs'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Delete Version
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox component='form' role='form'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                      <SoftBox display='flex' alignItems='center'>
                        <DeleteForeverIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant='h6' fontWeight='medium'>
                            Delete Version
                          </SoftTypography>
                          <SoftTypography
                            variant='button'
                            color='text'
                            fontWeight='regular'
                          >
                            Are you sure you want to delete version{' '}
                            <b>{version?.name}</b>? you will not be able to
                            recover this version!
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton
              variant='gradient'
              color='dark'
              sx={{ mt: 2 }}
              onClick={onClose}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant='gradient'
              color='info'
              type='submit'
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Delete
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the DeleteUserModal
DeleteVersionModal.propTypes = {
  display: PropTypes.string.isRequired,
  version: PropTypes.object.isRequired,
};

export default DeleteVersionModal;
