import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  service_fee: {
    fee: 0,
    unit: 'percentage',
  },
  country_fees: [],
};

export const schema = Yup.object().shape({
  service_fee: Yup.object().shape({
    fee: Yup.number().min(0).max(100).required('version fee is required'),
    unit: Yup.string().required('version fee unit is required'),
  }),
  country_fees: Yup.array(
    Yup.object().shape({
      country: Yup.string().required('country is required'),
      service_fee: Yup.object().shape({
        fee: Yup.number().min(0).max(100).required('country fee is required'),
        unit: Yup.string().required('country fee unit is required'),
      }),
    })
  ),
  tx_fees: Yup.array(
    Yup.object().shape({
      network: Yup.string().required('network is required'),
      fee: Yup.number().min(0).required('fee is required'),
      unit: Yup.string().required('fee unit is required'),
    })
  ).required('tx fees is required'),
});

export const sanitizeVersionRequest = ({
  id,
  service_fee,
  country_fees,
  tx_fees,
}) => {
  const payload = _.pickBy(
    {
      id,
      service_fee,
      country_fees,
      tx_fees,
    },
    _.identity
  );
  return { ...payload };
};
