import _ from 'lodash';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'utils/request';

const VERSIONS = '/settings/versions/';

const getVersions = () => request.get(VERSIONS);

export const useGetVersions = () => {
  return useQuery([VERSIONS], getVersions);
};

export const useDeleteVersion = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => request.delete(`${VERSIONS}${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([VERSIONS]);
    },
  });
};

export const useCreateVersion = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => request.post(`${VERSIONS}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries([VERSIONS]);
    },
  });
};

