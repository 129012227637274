import { currencies } from 'constants/currencies';
import { currenciesFlags } from 'constants/currenciesFlags';

import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';

function PaymentDetails({ details }) {
  const flag = _.get(currenciesFlags, details.from_currency.toLowerCase());
  const symbol = _.find(currencies, { code: details.from_currency }).symbol;
  return (
    <SoftBox
      component='li'
      display='flex'
      justifyContent='space-between'
      alignItems='flex-start'
      bgColor='grey-100'
      borderRadius='lg'
    >
      <SoftBox
        width='100%'
        display='flex'
        flexDirection='column'
        lineHeight={1}
      >
        <SoftBox>
          <SoftBox display='flex'>
            <SoftBox>
              <SoftAvatar src={flag} size='sm' alt='title' />
            </SoftBox>
            <SoftBox
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <SoftTypography
                component='div'
                variant='button'
                textTransform='capitalize'
                fontWeight='medium'
              >
                {_.get(details, 'from_currency', 'N/A')} {`->`}&nbsp;
                {_.get(details, 'to_currency', 'N/A')}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox>
          <SoftBox display='flex'>
            <SoftBox
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <SoftTypography variant='caption' color='text'>
                {symbol} {_.get(details, 'from_amount', 'N/A')} &nbsp; {`->`}
                &nbsp;
                {parseFloat(_.get(details, 'to_amount', 'N/A')).toFixed(2)}
                &nbsp;
                {_.get(details, 'to_currency', 'N/A')}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the SummaryItem
PaymentDetails.propTypes = {
  details: PropTypes.object.isRequired,
};

export default PaymentDetails;
