import {
  transactionInput,
  kashaTransactionConfirm,
  feesCalculation,
  vaultodyConfirm,
  completedTransaction,
} from 'pages/transaction-details/data/timeline';
import PropTypes from 'prop-types';
import TimelineItem from 'rootComponents/Timeline/TimelineItem';
import TimelineList from 'rootComponents/Timeline/TimelineList';

//components

const TransactionProgress = ({ data }) => {
  /**
   * @description
   * kashaConfirmStatus: 'in_progress' | 'done'
   * vaultodyConfirmStatus: 'in_progress' | 'done'
   * vaultodyConfirmHide: boolean
   * completedHide: boolean
   * Always show TransactionInput, FeesCalculation and KashaTransactionConfirm
   * Show VaultodyConfirm if kashaConfirmStatus is 'done'
   * Show CompletedTransaction if vaultodyConfirmStatus is 'done'
   */
  const kashaConfirmStatus =
    data?.verification == null ? 'in_progress' : 'done';
  let vaultodyConfirmStatus =
    data?.transaction?.tx_id == null ? 'in_progress' : 'done';
  vaultodyConfirmStatus =
    data?.transaction?.status == 'ERROR' ? 'error' : vaultodyConfirmStatus;
  const vaultodyConfirmHide = data?.verification == null ? true : false;
  const completedHide = data?.transaction?.tx_id == null ? true : false;

  const timelineData = [
    transactionInput(data?.transaction),
    feesCalculation(data?.transaction),
    kashaTransactionConfirm(data?.verification, kashaConfirmStatus, false),
    vaultodyConfirm(
      data?.transaction,
      vaultodyConfirmStatus,
      vaultodyConfirmHide
    ),
    completedTransaction(completedHide),
  ];

  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <TimelineList title='Transaction Progress'>
      {renderTimelineItems}
    </TimelineList>
  );
};
TransactionProgress.propTypes = {
  data: PropTypes.object.isRequired,
};
export default TransactionProgress;
