import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MiniStatisticsCard from 'rootComponents/Cards/StatisticsCards/MiniStatisticsCard';
import ComplexReportsDoughnutChart from 'rootComponents/Charts/DoughnutCharts/ComplexReportsDoughnutChart';
import { amountGroupByFromCurrencyReport } from 'utils/analytic';
import { getSymbolFromCurrency } from 'utils/string';

import { countriesFlags } from '../../../constants/countriesFlags';
import StatusSummary from './StatusSummary';

const limits = [
  '50',
  '100',
  '500',
  '1000',
  '5000',
  '10000',
  '20000',
  '50000',
  '100000',
];

function SummaryReport({ data, limitOnChange }) {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [menu, setMenu] = useState(null);
  const [doughnutChartData, setDoughnutChartData] = useState({});
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    limitOnChange(limits[0]);
    setSelectedIndex(0);
  }, []);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleMenuItemClick = (event, index) => {
    setSelected([]);
    setSelectedIndex(index);
    limitOnChange(limits[index]);
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
      variant='selectedMenu'
    >
      {limits.map((option, index) => (
        <MenuItem
          key={option}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
  );

  // Function to get an object from countriesFlags array based on the provided code

  useEffect(() => {
    let doughnutData;
    doughnutData = amountGroupByFromCurrencyReport(data);
    setDoughnutChartData(doughnutData);
  }, [data, selected]);

  return (
    <SoftBox py={3}>
      <SoftBox display='flex' justifyContent='flex-end' mb={3} ml={2}>
        <SoftButton variant='gradient' color='dark' onClick={openMenu}>
          {limits[selectedIndex]}&nbsp;
          <Icon>expand_more</Icon>
        </SoftButton>
        {renderMenu}
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <StatusSummary data={data} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <ComplexReportsDoughnutChart
              title='Summary by from currency'
              chart={doughnutChartData}
              tooltip='Summary amount by from currency'
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}
// Setting default values for the props of SummaryReport
SummaryReport.defaultProps = {
  data: [],
  limitOnChange: () => {},
};

// Typechecking props of the SummaryReport
SummaryReport.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  limitOnChange: PropTypes.func,
};

export default SummaryReport;
