import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import MiniVerificationCard from 'rootComponents/Cards/StatisticsCards/MiniVerificationCard';

// This code gets the transaction detail from the API and renders it as a tree view.
const DoneMessage = ({ url }) => {
  return (
    <Card>
      <SoftBox bgColor={'white'} variant='gradient'>
        <SoftBox pt={3} px={3}>
          <SoftTypography variant='h6' fontWeight='medium' color={'dark'}>
            Transaction Succeeded
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MiniVerificationCard
                bgColor='success'
                title={{
                  text: (
                    <>
                      <SoftTypography
                        variant='h6'
                        fontWeight='medium'
                        color={'dark'}
                      >
                        Transaction Succeeded
                      </SoftTypography>
                      <SoftTypography
                        variant='h6'
                        fontWeight='medium'
                        color={'light'}
                      >
                        <a href={url} target='_blank' rel='noopener noreferrer'>
                          Visit Blockchain explorer
                        </a>
                      </SoftTypography>
                    </>
                  ),
                  fontWeight: 'medium',
                }}
                amount={''}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};
DoneMessage.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DoneMessage;
