import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SoftBadge from 'components/SoftBadge';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';

// This code gets the transaction detail from the API and renders it as a tree view.
const UserInfo = ({ data }) => {
  const isKycVerified = _.get(data, 'user.verified_kyc', false);
  return (
    <Card id='basic-info' sx={{ overflow: 'visible' }}>
      <SoftBox p={3}>
        <SoftTypography variant='h5'>User Info</SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox lineHeight={3}>
              <SoftTypography variant='caption' color='text'>
                User ID:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'user.id', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox>
              <SoftTypography variant='caption' color='text'>
                Email:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'user.email', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={3}>
              <SoftTypography variant='caption' color='text'>
                First Name:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'user.first_name', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                Last Name:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'user.last_name', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                Phone:&nbsp;&nbsp;
                <SoftTypography variant='caption' fontWeight='medium'>
                  {_.get(data, 'user.phone', 'N/A')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={1} lineHeight={2}>
              <SoftTypography variant='caption' color='text'>
                KYC verified:&nbsp;&nbsp;
                <SoftBadge
                  color={isKycVerified ? 'success' : 'error'}
                  size='xs'
                  badgeContent={isKycVerified.toString()}
                  container
                />
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
};
UserInfo.propTypes = {
  data: PropTypes.object.isRequired,
};
export default UserInfo;
