import _ from 'lodash';
import * as Yup from 'yup';

export const defaultValues = {
  first_name: null,
  last_name: null,
  email: null,
  phone: null,
  role: null,
};

export const schema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is required')
    .max(100, 'First name is too long, should be less than 100 characters'),
  last_name: Yup.string()
    .required('Last name is required')
    .max(100, 'Last name is too long, should be under 100 characters'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  role: Yup.string().required('Role is required'),
});

export const sanitizeRequest = ({
  from_date,
  email,
  from_currency,
  limit,
  max_amount,
  min_amount,
  page,
  payment_method,
  session_id,
  status,
  to_date,
  to_currency,
  user_id,
  phone,
}) => {
  return _.pickBy(
    {
      from_date,
      email,
      from_currency,
      limit,
      max_amount,
      min_amount,
      page,
      payment_method,
      session_id,
      status,
      to_date,
      to_currency,
      user_id,
      phone,
    },
    _.identity
  );
};
