import { forwardRef } from "react";

// form
// @mui
import { Switch, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";
import { useFormContext, Controller } from "react-hook-form";

// ----------------------------------------------------------------------

const SwitchField = forwardRef(({ name, disabled, ...rest }, ref) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Switch {...field} disabled={disabled} checked={field.value} />}
        />
      }
      {...rest}
    />
  );
});

// Setting default values for the props of SwitchField
SwitchField.defaultProps = {
  name: "",
  disabled: false,
};

// Typechecking props for the SwitchField
SwitchField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
export default SwitchField;
