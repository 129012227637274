import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';

function FeesDetails({ fees }) {
  return (
    <SoftBox
      component='li'
      display='flex'
      justifyContent='space-between'
      alignItems='flex-start'
      bgColor='grey-100'
      borderRadius='lg'
    >
      <SoftBox
        width='100%'
        display='flex'
        flexDirection='column'
        lineHeight={1}
      >
        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant='caption' color='text'>
            Service Fee:&nbsp;&nbsp;
            <SoftTypography
              variant='caption'
              fontWeight='medium'
              textTransform='capitalize'
            >
              {parseFloat(_.get(fees, 'convert_fee.fee', 0)).toFixed(4)}{' '}
              {_.get(fees, 'convert_fee.unit', 'N/A')}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1} lineHeight={0}>
          <SoftTypography variant='caption' color='text'>
            Tx Fee:&nbsp;&nbsp;
            <SoftTypography variant='caption' fontWeight='medium'>
              {parseFloat(_.get(fees, 'tx_fee.fee', 0)).toFixed(6)}{' '}
              {_.get(fees, 'tx_fee.unit', 'N/A')}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// FeesDetails props for the SummaryItem
FeesDetails.propTypes = {
  fees: PropTypes.object.isRequired,
};

export default FeesDetails;
