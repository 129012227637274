import React from 'react';

import App from 'App';
import { SoftUIControllerProvider } from 'context';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Soft UI Context Provider

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>
);

