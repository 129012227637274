import React from "react";

import { SelectInputField } from "components/FormField";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import _ from "lodash";
import PropTypes from "prop-types";

const PaymentOptionSelect = ({ name, label, required, paymentOptions, ...rest }) => {
  const options = _.map(paymentOptions, (item) => {
    return {
      value: item.id,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={_.get(item, "logo", "")} size="sm" alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  return (
    <SelectInputField name={name} label={label} options={options} required={required} {...rest} />
  );
};

PaymentOptionSelect.defaultValues = {
  required: false,
  label: "",
  options: [],
};
PaymentOptionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  paymentOptions: PropTypes.array,
};

export default PaymentOptionSelect;
