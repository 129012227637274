import _ from 'lodash';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'utils/request';

const getNeedApprovalTransactions = () => {
  const url = `/bitmambo/admin/transactions/?status=WAITING_FOR_APPROVAL`;
  return request.get(url);
};

export const useGetNeedApprovalTransactions = () => {
  return useQuery(['approvals'], getNeedApprovalTransactions);
};

export const useApproveTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ transactionId }) => {
      const url = `/bitmambo/admin/transactions/${transactionId}/approve`;
      return request.post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('approvals');
      },
    }
  );
};
