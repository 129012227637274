import { Roles } from 'constants/roles';

import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import { TextInputField } from 'components/FormField';
import { SelectInputField } from 'components/FormField';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import { useDisclosure } from 'hooks/useDisclosure';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { useAddUser } from '../hooks';
import { schema, defaultValues, sanitizeRequest } from '../schemas';

const AddUserModal = ({ refetch }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, control } = methods;

  //hooks
  const { isLoading, mutate } = useAddUser();

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(sanitizeRequest(values), {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  const options = Object.keys(Roles).map((item) => ({
    value: item,
    label: item,
  }));

  const handleClose = () => {
    onClose();
    refetch();
  };

  return (
    <>
      <SoftBox pt={2} px={2}>
        <Stack spacing={1} direction='row' justifyContent='flex-end'>
          <SoftButton variant='gradient' color='info' onClick={onOpen}>
            Create User
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} handleClose={handleClose} fullWidth maxWidth='xs'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create User
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox component='form' role='form'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInputField label='First Name' name='first_name' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInputField label='Last Name' name='last_name' />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label='Email address' name='email' />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SelectInputField
                      name='role'
                      label='Role'
                      message='Required'
                      options={options}
                      placeholder='Select an role'
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextInputField label='Phone Number' name='phone' />
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton
              variant='gradient'
              color='dark'
              sx={{ mt: 2 }}
              onClick={handleClose}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant='gradient'
              color='info'
              type='submit'
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Create User
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
// Typechecking props for the ResetUserModal
AddUserModal.propTypes = {
  refetch: PropTypes.func.isRequired,
};
export default AddUserModal;
