import { currencies } from 'constants/currencies';
import { currenciesFlags } from 'constants/currenciesFlags';

import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fDateTimeSuffix } from 'utils/formatTime';

const SimpleTimelineItem = ({ label, value, dark }) => {
  return (
    <SoftBox display='flex'>
      <SoftBox display='flex' flexDirection='column' justifyContent='center'>
        <SoftTypography variant='caption' color={dark ? 'white' : 'text'}>
          {label}:&nbsp;&nbsp;
        </SoftTypography>
      </SoftBox>

      <SoftBox display='flex' flexDirection='column' justifyContent='center'>
        <SoftTypography
          component='div'
          textTransform='capitalize'
          variant='caption'
          fontWeight='medium'
          color={dark ? 'white' : 'text'}
        >
          {value}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
SimpleTimelineItem.defaultProps = {
  dark: false,
};
SimpleTimelineItem.propTypes = {
  dark: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export const transactionInput = (transaction) => {
  const flag = _.get(
    currenciesFlags,
    transaction?.payment_details?.from_currency?.toLowerCase()
  );
  const symbol = _.find(currencies, {
    code: transaction?.payment_details?.from_currency,
  })?.symbol;
  return {
    color: 'secondary',
    icon: 'payment',
    title: 'Transaction Input',
    dateTime: fDateTimeSuffix(_.get(transaction, 'created_date', null)),
    description: (
      <>
        <SoftBox display='flex'>
          <SoftBox
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <SoftTypography variant='caption' color='text'>
              From Currency:&nbsp;&nbsp;
            </SoftTypography>
          </SoftBox>

          <SoftBox>
            <SoftAvatar src={flag} size='sm' alt='title' />
          </SoftBox>
          <SoftBox
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <SoftTypography
              component='div'
              textTransform='capitalize'
              variant='caption'
              fontWeight='medium'
            >
              {_.get(transaction, 'payment_details.from_currency', 'N/A')}
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        <SimpleTimelineItem
          label='From Amount'
          value={`${symbol} ${_.get(
            transaction,
            'payment_details.from_amount',
            0
          )}`}
        />
      </>
    ),
    badges: ['DONE'],
  };
};

export const feesCalculation = (transaction) => {
  const serviceFeeSymbol = _.find(currencies, {
    code: transaction?.fees?.convert_fee?.unit,
  })?.symbol;
  return {
    color: 'secondary',
    icon: 'calculate',
    title: 'Fees Calculation',
    dateTime: fDateTimeSuffix(_.get(transaction, 'created_date', null)),
    description: (
      <>
        <SimpleTimelineItem
          label='Service Fee'
          value={`${serviceFeeSymbol} ${_.get(
            transaction,
            'fees.convert_fee.fee',
            0
          )}`}
        />
        <SimpleTimelineItem
          label='Tx Fee'
          value={`${_.get(transaction, 'fees.tx_fee.fee', 0)} ${_.get(
            transaction,
            'fees.tx_fee.unit',
            'N/A'
          )}`}
        />
      </>
    ),
    badges: ['DONE'],
  };
};

export const kashaTransactionConfirm = (verification, status, hide) => {
  const badgeStatus = status == 'done' ? 'DONE' : 'IN_PROGRESS';
  const paidCurrencySymbol =
    _.find(currencies, {
      code: verification?.paid_currency,
    })?.symbol || '';
  const color = status == 'done' ? 'secondary' : 'success';
  const date =
    _.get(verification, 'updated_date', null) == null
      ? ''
      : fDateTimeSuffix(_.get(verification, 'updated_date', null));
  if (hide) return {};
  return {
    color: color,
    icon: 'paid',
    title: 'Kasha Transaction Confirmation',
    dateTime: date,
    description: (
      <>
        <SimpleTimelineItem
          label='Kasha Session ID'
          value={` ${_.get(verification, 'session_id', 'N/A')}`}
        />
        <SimpleTimelineItem
          label='Paid Amount'
          value={`${paidCurrencySymbol} ${_.get(
            verification,
            'paid_amount',
            0
          )}`}
        />
      </>
    ),
    badges: [badgeStatus],
  };
};

export const vaultodyConfirm = (transaction, status, hide) => {
  let badgeStatus = 'IN_PROGRESS';
  let color = 'success';
  badgeStatus;
  switch (status) {
    case 'done':
      badgeStatus = 'DONE';
      color = 'secondary';
      break;
    case 'in_progress':
      color = 'success';
      badgeStatus = 'IN_PROGRESS';
      break;
    case 'error':
      color = 'error';
      badgeStatus = 'ERROR';
      break;
  }
  if (hide) return {};
  return {
    color: color,
    icon: 'lock',
    title: 'Vaultody Confirmation',
    dateTime: fDateTimeSuffix(_.get(transaction, 'updated_date', null)),
    description: (
      <>
        <SimpleTimelineItem
          label=' To Currency'
          value={_.get(transaction, 'payment_details.to_currency', 'N/A')}
        />
        <SimpleTimelineItem
          label='To Amount'
          value={`${_.get(transaction, 'payment_details.to_amount', 0)} ${_.get(
            transaction,
            'payment_details.to_currency',
            'N/A'
          )}`}
        />
        <SimpleTimelineItem
          label='Address'
          value={_.get(transaction, 'payment_details.address', 0)}
        />
        <SimpleTimelineItem
          label='Network'
          value={_.get(transaction, 'payment_details.network', 0)}
        />
      </>
    ),
    badges: [badgeStatus],
  };
};

export const completedTransaction = (hide) => {
  if (hide) return {};
  return {
    color: 'success',
    icon: 'check',
    title: 'Transaction Completed',
    dateTime: '',
    description: null,
    badges: [],
  };
};
