import _ from 'lodash';
import { feesCalculation } from 'pages/transaction-details/data/timeline';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useVersionStore } from 'stores/version';
import request from 'utils/request';
import { toastSuccess, toastError } from 'utils/toast';

const VERSIONS = '/settings/versions/';

const getVersion = (id) => request.get(`${VERSIONS}${id}`);
const getFees = (id) => request.get(`${VERSIONS}${id}/fees`);
const updateVersion = ({ id, ...data }) => {
  return request.put(`${VERSIONS}${id}`, { ...data });
};
const updateFee = ({ id, ...data }) => {
  return request.put(`${VERSIONS}${id}/fees`, { ...data });
};
const deleteFee = (id) => request.delete(`${VERSIONS}${id}/fees`);

export const useGetVersion = ({ id }) => {
  const { setCurrentVersion } = useVersionStore();
  return useQuery({
    queryKey: [VERSIONS, id],
    queryFn: () => getVersion(id),
    retry: 0,
    onError: () => {
      toastError({ description: 'Version not found' });
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    onSuccess: (data) => {
      setCurrentVersion(data);
    },
  });
};

export const useUpdateVersion = ({ id }) => {
  const queryClient = useQueryClient();

  return useMutation(updateVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries([VERSIONS, id]);
      toastSuccess({ description: 'Version updated successfully' });
    },
  });
};

// Fee
export const useGetFee = ({ id }) => {
  return useQuery({
    queryKey: [VERSIONS, 'fees', id],
    queryFn: () => getFees(id),
    retry: 0,
  });
};

export const useUpdateFee = ({ id }) => {
  const queryClient = useQueryClient();

  return useMutation(updateFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([VERSIONS, 'fees', id]);
      toastSuccess({ description: 'Fee updated successfully' });
    },
  });
};

export const useDeleteFee = ({ id }) => {
  const queryClient = useQueryClient();

  return useMutation(deleteFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([VERSIONS, 'fees', id]);
      toastSuccess({ description: 'Fee deleted successfully' });
    },
  });
};
