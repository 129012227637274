import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import Approvals from 'pages/approvals';
import Transactions from 'pages/transactions';
import UsersManagement from 'pages/users-management';
import Versions from 'pages/versions';
import Shop from 'rootComponents/Icons/Shop';

const EmptyComponent = () => {
  return <div></div>;
};

const routes = [
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <GroupIcon size='12px' />,
    collapse: [
      {
        name: 'Users',
        key: 'public-users',
        route: '/users/public',
        component: <UsersManagement type='user' />,
      },
      {
        name: 'Admin Users',
        key: 'admin-users',
        route: '/users/admin',
        component: <UsersManagement type='admin' />,
      },
    ],
  },
  { type: 'divider', key: 'divider-1' },
  {
    type: 'collapse',
    name: 'Transactions',
    key: 'transactions',
    icon: <Shop size='12px' />,
    route: '/transactions',
    noCollapse: true,
    component: <Transactions type='admin' />,
  },
  {
    type: 'collapse',
    name: 'Settings',
    key: 'settings',
    icon: <SettingsIcon size='12px' />,
    collapse: [
      {
        name: 'Versions',
        key: 'version',
        route: '/settings/versions',
        component: <Versions />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Approvals',
    key: 'approvals',
    icon: <Shop size='12px' />,
    route: '/approvals',
    noCollapse: true,
    component: <Approvals />,
  },
];

export default routes;

