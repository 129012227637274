import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { getQueryParam } from 'utils/string';
import { toastSuccess, toastError } from 'utils/toast';

const { default: request } = require('utils/request');

const GET_USERS = '/bitmambo/admin/users/';
const UPDATE_USER = '/user/lite/reset-account';
const DELETE_USER = '/user/lite/delete-account';
const ADD_USER = '/user/lite/create-account';

const getUsers = (payload) => {
  const queryParams = getQueryParam(payload);
  return request.get(`${GET_USERS}?${queryParams}`);
};

const resetUser = (data) => {
  return request.post(`${UPDATE_USER}`, data);
};
const deleteUser = ({ id, type }) => {
  return request.delete(`${DELETE_USER}/${id}?type=${type}`);
};
const addUser = (data) => {
  return request.post(`${ADD_USER}`, { ...data, type: 'admin' });
};
const genWhatsappOtp = (data) => {
  return request.post(`/user/whatsapp/generate-otp`, data);
};
const sendWhatsappOtp = (data) => {
  return request.post(`/user/whatsapp/send-otp`, data);
};

// get users
export const useGetUsers = () => {
  return useMutation(getUsers, {
    onError: (error) => {
      const message = _.get(error, 'response.data.message[0]');
      toastError({ description: message });
    },
  });
};

//reset user hook
export const useResetUser = () => {
  return useMutation(resetUser, {
    onSuccess: () => {
      toastSuccess({ description: 'User reset successfully' });
    },
  });
};

//delete user hook
export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USERS]);
      toastSuccess({ description: 'User deleted successfully' });
    },
  });
};

export const useAddUser = () => {
  const queryClient = useQueryClient();
  return useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USERS]);
      toastSuccess({ description: 'User added successfully' });
    },
  });
};

export const useGenerateWhatsappOtp = () => {
  return useMutation(genWhatsappOtp, {
    onSuccess: () => {
      toastSuccess({ description: 'Otp generated successfully' });
    },
  });
};

export const useSendWhatsappOtp = () => {
  return useMutation(sendWhatsappOtp, {
    onSuccess: () => {
      toastSuccess({ description: 'Otp sent successfully' });
    },
  });
};
