import * as React from 'react';
import { forwardRef } from 'react';

import colors from 'assets/theme/base/colors';
import SoftBox from 'components/SoftBox';
import styles from 'components/SoftSelect/styles';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';

const MultipleSelectField = forwardRef(
  ({ size, options, placeholder, name, label, success, ...rest }, ref) => {
    const { control } = useFormContext();
    const { light } = colors;

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <SoftBox
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            height='100%'
            mb={1.5}
          >
            {label && (
              <SoftBox mb={1} ml={0.5} lineHeight={0} display='inline-block'>
                <SoftTypography
                  component='label'
                  variant='caption'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  {label}
                </SoftTypography>
              </SoftBox>
            )}
            <Select
              error={error}
              {...rest}
              ref={ref}
              {...field}
              value={
                options.filter((option) =>
                  field?.value?.includes(option?.value)
                ) || null
              }
              onChange={(val) => {
                const selected = val?.map((item) => item.value);
                return field.onChange(selected || null);
              }}
              defaultValue={[]}
              isMulti
              name={name}
              options={options}
              styles={styles(size, error, success)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: light.main,
                  primary: light.main,
                },
              })}
            />
            {error && (
              <SoftBox mt={0.75}>
                <SoftTypography component='div' variant='caption' color='error'>
                  {_.get(error, 'message', 'Unknown error')}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        )}
      />
    );
  }
);

// Setting default values for the props of SwitchField
MultipleSelectField.defaultProps = {
  size: 'medium',
  name: '',
  disabled: false,
  label: '',
  options: [],
  placeholder: '',
  error: false,
  success: false,
};

// Typechecking props for the SwitchField
MultipleSelectField.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
};
export default MultipleSelectField;
