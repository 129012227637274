

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from '@mui/material';
import pxToRem from 'assets/theme/functions/pxToRem';
import FormProvider from 'components/FormProviders';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import { useDisclosure } from 'hooks/useDisclosure';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { useResetUser } from '../hooks';

const ResetUserModal = ({ user, type }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading, mutate } = useResetUser();

  // create a function that handles the form submission
  const onSubmit = () => {
    mutate(
      {
        user_id: user.id,
        type,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <SoftBox>
        <SoftButton variant='text' color='dark' onClick={onOpen}>
          <Icon>update</Icon>
        </SoftButton>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='xs'>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Reset User
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox component='form' role='form'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                    >
                      <SoftBox display='flex' alignItems='center'>
                        <RestartAltIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.primary.main,
                          }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant='h6' fontWeight='medium'>
                            Reset User
                          </SoftTypography>
                          <SoftTypography
                            variant='button'
                            color='text'
                            fontWeight='regular'
                          >
                            Password reset link will be sent to{' '}
                            <b>{user?.email}</b>
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton
              variant='gradient'
              color='dark'
              sx={{ mt: 2 }}
              onClick={onClose}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant='gradient'
              color='info'
              type='submit'
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Reset
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the ResetUserModal
ResetUserModal.propTypes = {
  type: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default ResetUserModal;
