import { currencies as allCurrencies } from 'constants/currencies';
import { currenciesFlags } from 'constants/currenciesFlags';

import React from 'react';

import { SelectInputField } from 'components/FormField';
import SoftAvatar from 'components/SoftAvatar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import _ from 'lodash';
import PropTypes from 'prop-types';


const CurrencySelect = ({ currencies, name, label, required, ...rest }) => {
  const currenciesToShow = _.isEmpty(currencies)
    ? allCurrencies.slice(1, -1)
    : currencies;
  const currenciesOptions = currenciesToShow.map((item) => {
    const flag = _.get(currenciesFlags, item.code.toLowerCase());
    return {
      value: item.code,
      label: (
        <SoftBox display='flex' py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size='sm' alt='title' />
          </SoftBox>
          <SoftBox
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            <SoftTypography
              component='div'
              variant='button'
              textTransform='capitalize'
              fontWeight='medium'
            >
              {item.symbol} {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  return (
    <SelectInputField
      name={name}
      label={label}
      options={currenciesOptions}
      required={required}
      {...rest}
    />
  );
};

CurrencySelect.defaultValues = {
  required: false,
  label: '',
  currencies: [],
};
CurrencySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  currencies: PropTypes.array,
};

export default CurrencySelect;
